var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-2/5 p-6 bg-white rounded-lg shadow" },
    [
      _c("div", [
        _c("header", [
          this.new
            ? _c("div", [
                _c("h4", [_vm._v(_vm._s(_vm.$t("apps.contact-phones.add")))]),
                _c("p", { staticClass: "text-base" }, [
                  _vm._v(_vm._s(_vm.$t("apps.contact-phones.add-description")))
                ])
              ])
            : _c("div", [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$t("apps.contact-phones.update")))
                ]),
                _c("p", { staticClass: "text-base" }, [
                  _vm._v(
                    _vm._s(_vm.$t("apps.contact-phones.update-description"))
                  )
                ])
              ])
        ])
      ]),
      _c("LabelForm", [_vm._v(_vm._s(_vm.$t("shops.resorts.title")))]),
      _c("v-select", {
        staticClass: "w-1/2",
        attrs: {
          options: _vm.resorts.map(function(resort) {
            return { label: resort.name, value: resort.uuid }
          }),
          placeholder: _vm.$t("shops.resorts.title")
        },
        model: {
          value: _vm.resortSelected,
          callback: function($$v) {
            _vm.resortSelected = $$v
          },
          expression: "resortSelected"
        }
      }),
      _c("LabelForm", [_vm._v(_vm._s(_vm.$t("banner.form.language")))]),
      _c("v-select", {
        staticClass: "w-1/2",
        attrs: {
          multiple: "",
          options: _vm.languages.map(function(lang) {
            return { label: lang.name, value: lang.acronym }
          }),
          placeholder: _vm.$t("banner.form.language")
        },
        model: {
          value: _vm.languagesSelected,
          callback: function($$v) {
            _vm.languagesSelected = $$v
          },
          expression: "languagesSelected"
        }
      }),
      _c("LabelForm", [_vm._v(_vm._s(_vm.$t("timeofyear")))]),
      _c(
        "vs-select",
        {
          staticClass: "w-1/2",
          model: {
            value: _vm.timeofyearSelected,
            callback: function($$v) {
              _vm.timeofyearSelected = $$v
            },
            expression: "timeofyearSelected"
          }
        },
        _vm._l(this.timeOfYear, function(time, index) {
          return _c("vs-select-item", {
            key: index,
            attrs: { value: time.value, text: time.name }
          })
        }),
        1
      ),
      _c("LabelForm", [_vm._v(_vm._s(_vm.$t("shops.type")))]),
      _c(
        "vs-select",
        {
          staticClass: "w-3/5 selectExample ",
          model: {
            value: _vm.typeSelected,
            callback: function($$v) {
              _vm.typeSelected = $$v
            },
            expression: "typeSelected"
          }
        },
        _vm._l(this.types, function(type, index) {
          return _c("vs-select-item", {
            key: index,
            attrs: { value: type.id, text: type.name }
          })
        }),
        1
      ),
      _vm.typeSelected == 4
        ? _c(
            "div",
            [
              _c("LabelForm", [_vm._v(_vm._s(_vm.$t("title")))]),
              _c("vs-input", {
                staticClass: "w-1/2",
                model: {
                  value: _vm.title,
                  callback: function($$v) {
                    _vm.title = $$v
                  },
                  expression: "title"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("LabelForm", [
        _vm._v(_vm._s(_vm.$t("apps.contact-phones.phonenumber")))
      ]),
      _c("vs-input", {
        staticClass: "w-1/2",
        model: {
          value: _vm.phone,
          callback: function($$v) {
            _vm.phone = $$v
          },
          expression: "phone"
        }
      }),
      _c(
        "b-alert",
        {
          staticClass: "my-5 rounded-lg",
          attrs: { show: _vm.errorsOnSave.length > 0, variant: "danger" }
        },
        [
          _c(
            "ul",
            { staticClass: "list-none" },
            _vm._l(_vm.errorsOnSave, function(errorString, index) {
              return _c("li", { key: index }, [_vm._v(_vm._s(errorString))])
            }),
            0
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled rounded-lg w-48 mr-10" },
              on: {
                click: function($event) {
                  return _vm.saveContactPhone()
                }
              }
            },
            [
              this.new
                ? _c("span", [_vm._v(_vm._s(_vm.$t("shops.save")))])
                : _c("span", [_vm._v(_vm._s(_vm.$t("shops.update")))])
            ]
          ),
          _c(
            "vs-button",
            {
              staticClass: "w-48 rounded-lg",
              attrs: { color: "#242426", type: "border" },
              on: {
                click: function($event) {
                  return _vm.$router.push(
                    "/apps/" + _vm.applicationUuid + "/contact-phones"
                  )
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("shops.cancel")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }