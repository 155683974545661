<template>
    <div class="w-2/5 p-6 bg-white rounded-lg shadow">
        <div>
            <header>
                <div v-if="this.new">
                    <h4>{{ $t("apps.contact-phones.add") }}</h4>
                    <p class="text-base">{{ $t("apps.contact-phones.add-description") }}</p>
                </div>
                <div v-else>
                    <h4>{{ $t("apps.contact-phones.update") }}</h4>
                    <p class="text-base">{{ $t("apps.contact-phones.update-description") }}</p>
                </div>
            </header>
        </div>

        <LabelForm>{{ $t("shops.resorts.title") }}</LabelForm>
        <v-select class="w-1/2"
            v-model="resortSelected"
            :options="resorts.map((resort) => ({ label: resort.name, value: resort.uuid }))"
            :placeholder="$t('shops.resorts.title')"/>

        <LabelForm>{{ $t("banner.form.language") }}</LabelForm>
        <v-select multiple
            class="w-1/2"
            v-model="languagesSelected"
            :options="languages.map((lang) => ({ label: lang.name, value: lang.acronym }))"
            :placeholder="$t('banner.form.language')"/>

        <LabelForm>{{ $t("timeofyear") }}</LabelForm>
        <vs-select class="w-1/2" v-model="timeofyearSelected">
            <vs-select-item v-for="(time, index) in this.timeOfYear" :key="index" :value="time.value" :text="time.name" />
        </vs-select>

        <LabelForm>{{ $t("shops.type") }}</LabelForm>
        <vs-select class="w-3/5 selectExample " v-model="typeSelected">
            <vs-select-item v-for="(type, index) in this.types" :key="index" :value="type.id" :text="type.name" />
        </vs-select>

        <div v-if="typeSelected == 4">
            <LabelForm>{{ $t("title") }}</LabelForm>
            <vs-input class="w-1/2" v-model="title"/>
        </div>

        <LabelForm>{{ $t("apps.contact-phones.phonenumber") }}</LabelForm>
        <vs-input class="w-1/2" v-model="phone"/>

        <b-alert :show="errorsOnSave.length > 0" variant="danger" class="my-5 rounded-lg">
            <ul class="list-none">
                <li v-for="(errorString, index) in errorsOnSave" :key="index">{{ errorString }}</li>
            </ul>
        </b-alert>

        <div class="mt-5">
            <vs-button color="primary"
                type="filled rounded-lg w-48 mr-10"
                @click="saveContactPhone()">
                    <span v-if="this.new">{{ $t("shops.save") }}</span>
                    <span v-else>{{ $t("shops.update") }}</span>
            </vs-button>
            <vs-button color="#242426"
                type="border"
                class="w-48 rounded-lg"
                @click="$router.push(`/apps/${applicationUuid}/contact-phones`)">
                    {{ $t("shops.cancel") }}
            </vs-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import LanguageService from '@/modules/Shared/Services/LanguageService'
import ContactPhoneService from '@/modules/Apps/Services/ContactPhoneService'
import store from "@/modules/Shared/Store/store";

export default {
    name: 'contact-phone-form',
    components: {
        LabelForm
    },
    data() {
        return {
            applicationUuid: '',
            errorsOnSave:[],
            languages: [],
            types: [],
            resortSelected: {},
            languagesSelected: [],
            timeofyearSelected: 'always',
            typeSelected: 0,
            title: '',
            phone: ''
        }
    },
    async beforeRouteEnter (to, from, next) {
        const contactPhone = to.params.contactPhone
        await store.dispatch('setRouteElement', contactPhone)
        next()
    },
    props: {
        contactphoneUuid: String,
        contactPhone: Object,
        new: Boolean
    },
    async created() {
        this.applicationUuid = this.$route.params.uuid
        this.languages = await LanguageService.getLanguages();
        this.types = await ContactPhoneService.getContactPhoneTypes();
        if (this.contactPhone && !this.new) {
            this.resortSelected = this.contactPhone.resort ? { label: this.contactPhone.resort.name, value: this.contactPhone.resort.uuid } : {}
            this.languagesSelected = this.contactPhone.languages.reduce((carry, currentLang) => {
                const langName = this.languages.filter((lang) => lang.acronym == currentLang.acronym)[0]
                return [...carry, { label: langName.name, value: currentLang.acronym }]
            }, [])
            this.timeofyearSelected = this.contactPhone.time_of_year
            this.typeSelected = this.contactPhone.type.id
            this.title = this.contactPhone.title
            this.phone = this.contactPhone.phone
        }
    },
    computed: {
        ...mapGetters( ['timeOfYear'] ),
        resorts () {
            return this.$store.state.resorts.resorts;
        }
    },
    methods: {
        async saveContactPhone() {
            const payload = {
                resort_uuid: this.resortSelected.value,
                languages: this.languagesSelected.reduce((carry, currentLang) => [...carry, currentLang.value], []),
                type: this.typeSelected,
                title: this.title,
                phone: this.phone,
                timeofyear: this.timeofyearSelected
            }
            let response;

            if (!this.hasErrors()) {
                if (this.contactPhone && !this.new) {
                    response = await ContactPhoneService.saveContactPhone(this.applicationUuid, this.contactphoneUuid, payload)
                } else {
                    response = await ContactPhoneService.createContactPhone(this.applicationUuid, payload)
                }
            }

            if (response.code == 200) {
                const text = this.new ? this.$t("apps.contact-phones.created.ok") : this.$t("apps.contact-phones.updated.ok")
                this.$vs.notify( { text: text, color: 'success',position:'top-right' } )
                setTimeout(() => {
                    this.$router.push(`/apps/${this.applicationUuid}/contact-phones`);
                }, 1000)
            } else {
                this.$vs.notify( { text: this.new ? this.$t("apps.contact-phones.created.ko") : this.$t("apps.contact-phones.updated.ko"), color: 'danger',position:'top-right'})
            }
        },
        hasErrors() {
            this.errorsOnSave = []

            if (this.languagesSelected.length == 0) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("languageempty")]
            }

            if (!this.typeSelected) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("typeisnotselected")]
            }

            if (!this.phone) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("shops.errors.phone_is_required")]
            }

            if (this.typeSelected == 4 && !this.title) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("apps.contact-phones.error.title_is_required")]
            }

            return this.errorsOnSave.length !== 0;
        }
    }
}
</script>
