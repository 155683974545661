<template>
    <DatatablePageLayout>
        <template v-slot:actionButtons>
                <vs-button 
                    class="rounded-lg xs:w-full sm:w-auto" 
                    :to="{ name: 'add-contact-phone', params: { 'contactphoneUuid': '', 'contactPhone' : {}, 'new': true } }" >
                    {{ $t("apps.contact-phones.add") }}
                </vs-button>
        </template>
            
        <template v-slot:datatable>
            <Datatable 
                :config="datatableConfig"
                :elements="contactPhonesTable"
                :stripe="true"
                :draggable="true"
                :sst="false"
                @sort="handleSort"
                @action="handleAction"
            />
        </template>
    </DatatablePageLayout>
</template>

<script>
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import ContactPhoneService from '@/modules/Apps/Services/ContactPhoneService'
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout";

export default {
    name: 'contact-phones',
    components: {
        Datatable,
        DatatablePageLayout,
    },
    data () {
        return {
            applicationUuid: '',
            contactPhones: [],
            contactPhonesTable: [],
            datatableConfig: [
                { 'field' : 'phone', head : this.$t("shops.phone"), 'searchable' : false, 'sortable' : false },
                { 'field' : 'languages', head : this.$t("banner.form.language"), 'searchable' : false, 'sortable' : false },
                { 'field' : 'type', head : this.$t("shops.type"), 'searchable' : false, 'sortable' : false },
                { 'field' : 'resort', head : this.$t("shops.resorts.title"), 'searchable' : false, 'sortable' : false },
                { 'field' : 'actions', 'type': 'actions', head : this.$t("banner.table.actions"), 'searchable' : false, 'sortable' : false }
            ],
        }
    },
    async created() {
        this.applicationUuid = this.$route.params.uuid
        this.contactPhones = await ContactPhoneService.getContactPhones(this.applicationUuid)
        this.contactPhonesTable = this.contactPhones.map(contact => ({
            'uuid' : contact.uuid,
            'phone' : contact.phone,
            'languages' : contact.languages.reduce((carry, lang) => ([...carry, lang.acronym.toUpperCase()]), []).join(', '),
            'type' : contact.type.name,
            'resort' : contact.resort ? contact.resort.name : '',
            'ordering' : contact.ordering,
            'actions' : [ 'edit', 'delete' ]
        }))
    },
    methods: {
        handleSort (contactPhonesOrdered) {
            ContactPhoneService.sortContactPhones(this.applicationUuid, contactPhonesOrdered)
        },
        handleAction (type, uuid) {
            const contactPhone = this.contactPhones.filter(contactPhone => contactPhone.uuid === uuid)[0];
            switch(type) {
                case 'edit':   
                    this.$router.push({
                        name: 'edit-contact-phone',
                        params: {
                            'contactphoneUuid': uuid,
                            'contactPhone': contactPhone,
                            'new': false
                        }
                    });
                break;
                case 'delete':
                    this.deleteContactPhone(uuid)
                break;
            }
        },
        contactPhoneDialogDelete(uuid) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t("apps.contact-phones.remove"),
                text: this.$t("question.sure"),
                accept: this.deleteContactPhone.bind(null, uuid)
            })
        },
        async deleteContactPhone(uuid) {
            this.$vs.loading();
            let response = await ContactPhoneService.deleteContactPhone(this.applicationUuid, uuid);
            
            if(response.code == 200) {
                this.contactPhonesTable = this.contactPhonesTable.filter((contact) => contact.uuid !== uuid)
                this.$vs.notify( { text: this.$t("apps.contact-phones.removed.ok"), color: 'success',position:'top-right' } )
            } else {
                this.$vs.notify( { text: this.$t("apps.contact-phones.removed.ko"), color: 'danger',position:'top-right'} )
            }

            this.$vs.loading.close();
        }
    }
}
</script>