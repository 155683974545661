var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatatablePageLayout", {
    scopedSlots: _vm._u([
      {
        key: "actionButtons",
        fn: function() {
          return [
            _c(
              "vs-button",
              {
                staticClass: "rounded-lg xs:w-full sm:w-auto",
                attrs: {
                  to: {
                    name: "add-contact-phone",
                    params: {
                      contactphoneUuid: "",
                      contactPhone: {},
                      new: true
                    }
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("apps.contact-phones.add")) + " ")]
            )
          ]
        },
        proxy: true
      },
      {
        key: "datatable",
        fn: function() {
          return [
            _c("Datatable", {
              attrs: {
                config: _vm.datatableConfig,
                elements: _vm.contactPhonesTable,
                stripe: true,
                draggable: true,
                sst: false
              },
              on: { sort: _vm.handleSort, action: _vm.handleAction }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }